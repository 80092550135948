import React from 'react'
import otg from '../../assets/otg.png'
import pinapple from '../../assets/pinapple.jpg'
import williamsb from '../../assets/williamsb.jpg'
import straw from '../../assets/straw.jpg'
import banana from '../../assets/banana.jpg'
import bananaup from '../../assets/bananaup.JPG'
import pota from '../../assets/pota.JPG'
import potato from '../../assets/potato.JPG'
import ban from '../../assets/ban.JPG'


function Carausel(props) {
    return (
        <div className=''>
            <div className="carousel carousel-center py-5 px-4 space-x-3 bg-accent">
                <div className="carousel-item">
                    <img src={otg} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item">
                    <img src={williamsb} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item">
                    <img src={straw} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item rounded-box">
                    <img src={banana} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item rounded-box">
                    <img src={bananaup} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item rounded-box">
                    <img src={pota} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item">
                    <img src={ban} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item">
                    <img src={potato} alt='sample product' className='carousel__img rounded-box'/>
                </div>
                <div className="carousel-item">
                    <img src={pinapple} alt='sample product' className='carousel__img rounded-box'/>
                </div>
            </div>
        </div>
    )
}

export default Carausel