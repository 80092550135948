import React from 'react'
import otg from '../../assets/otg.png'

function Footer() {
    return (
        <div>
            <div className="bg-base-300 py-7"></div>
            <footer className='py-16 bg-neutral'>
                <div className="container mx-auto">
                    <div className="md:flex md:justify-center">
                        <div className="md:w-1/4 p-3">
                            <div className="flex justify-center">
                                <img src={otg} className='rounded-xl' alt='otg'/>
                            </div>  
                        </div>
                        <div className="md:w-1/4 p-3">
                            <h4 className="text-accent font-bold text-center text-2xl mb-4">Funding, Support & Partnership</h4>
                            <p className="text-sm text-white text-center">
                            We are proud to be supported by MbeleNaBiz, with Reference Number BP1423, for our impactful project dedicated to enhancing livelihoods in [County]. This initiative focuses on elevating livestock production through the development of top-tier, safe, and cost-effective feeds. Our innovative approach incorporates the use of Hermetia illucens (Black Soldier Fly) larvae as an alternative protein source, ensuring sustainability and efficiency in farming practices.

                            Additionally, we have established a valuable partnership with Crossfrontier International Ltd to ensure the supply of high-quality, disease-free planting material. This collaboration underscores our commitment to excellence and our dedication to fostering holistic solutions for agricultural advancement.
                            </p>
                        </div>
                        <div className="md:w-1/4 p-3">
                            <h4 className="text-accent font-bold text-center text-2xl mb-4">Usefull Links</h4>
                            <div className="space-y-2 text-center">
                                <p className="text-gray-300 hover:text-secondary"><a href="/">Home</a></p>
                                <p className="text-gray-300 hover:text-secondary"><a href="/about">About</a></p>
                                <p className="text-gray-300 hover:text-secondary"><a href="/order">Order</a></p>
                                {/* <p className="text-gray-300 hover:text-secondary"><a href="/livestock">Our Products</a></p> */}
                                {/* <p className="text-gray-300 hover:text-secondary"><a href="/team">The Team</a></p> */}
                                <p className="text-gray-300 hover:text-secondary"><a href="/programs">Programs</a></p>
                                <p className="text-gray-300 hover:text-secondary"><a href="/contact">Contact Us</a></p>                                
                            </div>  
                        </div>
                        
                        <div className="md:w-1/4 p-3">
                            <h4 className="text-accent font-bold text-center text-2xl mb-4">Contacts</h4>
                            <div className="text-center">
                                <div className="text-white text-lg mb-4">
                                    We are located at:
                                    <div>Nairobi, Mirema Lane</div>
                                </div>
                                <p className="text-accent text-xl mb-4 font-bold">Get Us On</p>
                                <p className="text-white"> +254 726 566940</p>
                                {/* <p className="text-white mb-2">+254722585917</p> */}
                                <p className="text-white">otgholdings@gmail.com</p>
                                {/* <p className="text-white">info@lapssetlivestock.com </p> */}
                            </div>  
                        </div>
                    </div>
                </div>
            </footer>
            <div className="py-7 px-3 bg-black">
                <p className="text-white text-lg text-center">Copyright &copy; DAB AFRICA 2024.</p>
            </div>
        </div>
    )
}

export default Footer