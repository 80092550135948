import React from 'react'
import { Helmet } from 'react-helmet'
import Carausel from '../components/home/Carausel'
import Products from '../components/home/Products'
import Order from '../components/home/Order'
import Count from '../components/home/Count'

function HomePage() {
    return (
        <div>
            <Helmet>
                <title>Home ~ OTG HOLDINGS</title>
            </Helmet>
            <div className='mt-3 mb-6'>
                <Carausel />
            </div>
            <h1 className="text-center text-3xl text-accent font-bold mt-16 mb-7">OTG HOLDINGS </h1>
            <div className='md:flex md:justify-center md:space-x-3 py-7 md:items-center'>
                <div className="md:w-5/12 ">
                    <h3 className="text-accent font-bold text-center text-3xl mb-3">Who are we?</h3>
                    <p className="text-center text-md mb-3">
                    Established in 2014, OTG Holdings Ltd stands as a compelling testament to the enduring power of family-driven ambition and the innovative utilization of research to effectively tackle modern-day challenges. Rooted in a rich heritage of family ownership, our enterprise embodies a steadfast commitment to leveraging the latest advancements in science and technology to pave the way for groundbreaking solutions across various sectors.                    </p>
                    <p className="text-center text-md mb-3">
                        OTG supplies the followig Counties of; <span className='font-bold text-info'>Embu and its environs, Kiambu, Meru, Nyeri, Nairobi, and Many other regions</span>.
                    </p>

                </div>
                <div className="md:w-5/12 md:flex md:items-center">
                    <div className="">
                        <h3 className="text-accent font-bold text-center text-3xl mb-3">Our MOTTO:</h3>
                        <p className="text-center text-md mb-5">
                            "Empowering Communities and Environments through Cutting-Edge Innovation"
                        </p>
                        <h3 className="text-accent font-bold text-center text-3xl mb-3">Our Mision:</h3>
                        <p className="text-center text-md mb-5">
                        To harness the power of science and its tools, pioneering transformative solutions across diverse sectors including agribusiness, healthcare, education, renewable energy, environmental conservation, and climate change. Our aim is to cultivate sustainable market systems that benefit both society and the environment.                        </p>
                    </div>
                </div>
            </div>
            <Products />
            <Order />
            <Count />
        </div>
    )
}

export default HomePage