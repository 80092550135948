import React from 'react'
import { Helmet } from 'react-helmet'
// import bulls from '../assets/fat_bull.jpg'
// import hay from '../assets/hay_images.jpeg'
// import market from '../assets/market.jfif'
// import holding_ground from '../assets/borana_holding ground.jpg'

function ProgramsPage() {
    return (
        <div>
            <Helmet>
                <title>Programs ~ OTG HOLDING</title>
            </Helmet>
            <div className="mt-7 md:gap-3 mb-20">
                <div className="px-3 mb-7">
                    <h1 className="text-3xl mb-7 text-accent text-center">Livestock Feed Formulation Program</h1>
                    <div className="md:flex md:justify-center gap-9">
                        <div className="md:w-4/12 mb-3">
                            {/* <img src={bulls} alt='animal fattening' className='rounded-xl h-90' /> */}
                        </div>
                        <div className="md:w-5/12">
                            <p className="mb-7 ">
                                Objective: Develop high-quality, safe, and cost-effective livestock feed using Black Soldier Fly larvae as an alternative protein source.
                                Activities:
                                Research and development of feed formulations using Black Soldier Fly larvae.
                                Collaboration with experts in biotechnology, agriculture, and nutrition.
                                Testing and validation of feed formulations through field trials.
                                Training and capacity building for farmers on the benefits and usage of the formulated feed.
                                Impact: Increased livestock production, improved livelihoods for farmers, and reduced reliance on expensive or environmentally damaging feed ingredients.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="px-3 mb-7">
                    <h1 className="text-3xl mb-7 text-accent text-center">Agribusiness Market Systems Development Program</h1>
                    <div className="md:flex md:justify-center gap-9">
                        <div className="md:w-4/12 mb-3">
                            {/* <img src={hay} alt='animal fattening' className='rounded-xl' /> */}
                        </div>
                        <div className="md:w-5/12">
                            <p className="mb-7 ">
                                Objective: Create sustainable market systems for small-scale farmers in the region, particularly focusing on bananas and Irish potato seeds.
                                Activities:
                                Market research to understand demand and supply dynamics.
                                Partnering with local farmers' cooperatives and agricultural organizations.
                                Providing technical assistance and training to farmers on best practices in crop cultivation and post-harvest management.
                                Facilitating access to markets for farmers through partnerships with buyers and distributors.
                                Impact: Enhanced market access, increased income for farmers, and improved food security in the region.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="px-3 mb-7">
                    <h1 className="text-3xl mb-7 text-accent text-center">Technology Innovation Program</h1>
                    <div className="md:flex md:justify-center gap-9">
                        <div className="md:w-4/12 mb-3">
                            {/* <img src={holding_ground} alt='animal fattening' className='rounded-xl' /> */}
                        </div>
                        <div className="md:w-5/12">
                            <p className="mb-7 ">
                                Objective: Harness cutting-edge technologies, including biotechnology and artificial intelligence, to address challenges in agriculture, health, education, energy, and environmental conservation.
                                Activities:
                                Investment in research and development of innovative solutions.
                                Collaboration with academic institutions, research organizations, and technology companies.
                                Pilot testing of new technologies in partnership with target communities or sectors.
                                Training and capacity building for stakeholders on the use and benefits of technology solutions.
                                Impact: Improved efficiency, productivity, and sustainability across various sectors, leading to positive social and environmental outcomes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="px-3 mb-7">
                    <h1 className="text-3xl mb-7 text-accent text-center">Community Engagement and Outreach Program</h1>
                    <div className="md:flex md:justify-center gap-9">
                        <div className="md:w-4/12 mb-3">
                            {/* <img src={market} alt='animal fattening' className='rounded-xl' /> */}
                        </div>
                        <div className="md:w-5/12">
                            <p className="mb-3">
                                Objective: Foster positive impact on communities and environments through awareness-raising, education, and community engagement activities.
                            </p>
                            <p className="mb-3">
                                Activities:
                                Organizing workshops, seminars, and awareness campaigns on topics such as environmental conservation, climate change mitigation, and sustainable agriculture practices.
                                Establishing demonstration farms or model sites to showcase best practices and technologies.
                                Engaging with schools and educational institutions to incorporate environmental and agricultural education into curricula.
                                Collaborating with local NGOs, government agencies, and community groups to implement joint projects and initiatives.
                            </p>
                            <p className="mb-3">
                                Impact: Increased awareness and adoption of sustainable practices, strengthened community resilience, and enhanced environmental stewardship.
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ProgramsPage